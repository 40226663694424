var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.roundTo = exports.parseValue = void 0;
/**
 * parseValue ensures the value is a number and within accepted range.
 */

function parseValue(value) {
  const val = parseFloat(value === null || value === void 0 ? void 0 : value.toString());

  if (isNaN(val)) {
    throw new Error(`Input value is not a number`);
  }

  if (val > Number.MAX_SAFE_INTEGER || val < Number.MIN_SAFE_INTEGER) {
    throw new RangeError("Input value is outside of safe integer range");
  }

  return val;
}

exports.parseValue = parseValue;
/**
 * Rounds a number [value] up to a specified [precision].
 */

function roundTo(value, precision) {
  if (!Number.isFinite(value)) {
    throw new Error("Input value is not a finite number");
  }

  if (!Number.isInteger(precision) || precision < 0) {
    throw new Error("Precision is not a positive integer");
  }

  if (Number.isInteger(value)) {
    return value;
  }

  return parseFloat(value.toFixed(precision));
}

exports.roundTo = roundTo;
export default exports;