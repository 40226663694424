var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultOptions = void 0;
/**
 * Default options for Millify.
 */

exports.defaultOptions = {
  decimalSeparator: ".",
  lowercase: false,
  precision: 1,
  space: false,
  units: ["", "K", "M", "B", "T", "P", "E" // Quintillion
  ]
};
export default exports;